<template>
  <!-- <div class="flex items-center justify-center bg-light-gray rounded-lg w-full h-96">
    <BaseImage
      src="/employers/company-information.png"
      alt=""
      sizes="300px"
      class="aspect-[2/3] w-full rounded-xl bg-dark-gray/5 object-cover shadow-lg"
    />
  </div> -->
  <BaseImage src="/employers/company-information.png" alt="" class="w-full rounded-xl bg-dark-gray/5 object-cover" />
</template>
